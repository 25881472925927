import { DATABASE_FIELDS_KEYS } from '~/components/features/Form/formUtils'
import { GatsbyActiveEnvs } from '~/utils/types'

// -  -  - important -  -  -
const IS_TEST_MODE = false
const FORCED_ENV: GatsbyActiveEnvs = undefined
// -  -  -  -  -  -  -  -  -

const SETTINGS_BY_ENV = {
  preprod: {
    showQuestionCustomImage: true,
    isEvaluationPageGenerated: true,
    apiFile: 'saveinfopreprod.php',
    formFieldsToDisplayWithSorting: [
      'company',
      'lastName',
      'firstName',
      'position',
      'email',
      'phone',
    ] as DATABASE_FIELDS_KEYS[],
  },
  prod: {
    showQuestionCustomImage: false,
    isEvaluationPageGenerated: false,
    apiFile: 'saveinfoprod.php',
    formFieldsToDisplayWithSorting: [
      'lastName',
      'firstName',
      'email',
      'position',
      'company',
    ] as DATABASE_FIELDS_KEYS[],
  },
}

export const SETTINGS = {
  prefillFormFields: false,
  disableFormSubmission: IS_TEST_MODE,
  isAnswerMandatoryToGoToNextQuestion: !IS_TEST_MODE,
  answersMaxCount: 4,
  questionsMaxCount: 8,
  ...SETTINGS_BY_ENV[FORCED_ENV ?? process.env.GATSBY_ACTIVE_ENV],
}

export const PAGES_SLUGS = {
  '/': '/',
  evaluation: 'evaluation',
  results: 'results',
}

export type pagesSlugs = keyof typeof PAGES_SLUGS
