import { FormFieldsConfig } from '~/utils/types'

export const DATABASE_FIELDS = {
  company: '',
  lastName: '',
  firstName: '',
  email: '',
  phone: '',
  position: '',
  score: '',
  answers: '',
  scorepercentage: '',
  cid: '',
}
export type DATABASE_FIELDS = typeof DATABASE_FIELDS
export type DATABASE_FIELDS_KEYS = keyof DATABASE_FIELDS

export const FORM_FIELDS_PREFILLED: DATABASE_FIELDS = {
  company: 'John Doe & Cie.',
  lastName: 'Doe',
  firstName: 'John',
  email: 'john.doe@gmail.com ',
  phone: '0123465678',
  position: 'Dev',
  score: '',
  answers: '',
  scorepercentage: '',
  cid: '',
}

export const FORM_FIELDS_CONFIG: FormFieldsConfig = [
  {
    name: 'company',
    type: 'text',
    placeholder: 'Société',
    errorMessage: 'Ce champs est requis.',
    validate: (value: string) => value?.trim() !== '',
    isMandatory: true,
  },
  {
    name: 'lastName',
    type: 'text',
    placeholder: 'Nom',
    errorMessage: 'Ce champs est requis.',
    validate: (value: string) => value?.trim() !== '',
    isMandatory: true,
  },
  {
    name: 'firstName',
    type: 'text',
    placeholder: 'Prénom',
    errorMessage: 'Ce champs est requis.',
    validate: (value: string) => value?.trim() !== '',
    isMandatory: true,
  },
  {
    name: 'position',
    type: 'text',
    placeholder: 'Fonction',
    errorMessage: 'Ce champs est requis.',
    validate: (value: string) => value?.trim() !== '',
    isMandatory: true,
  },
  {
    name: 'email',
    type: 'email',
    placeholder: 'Adresse email',
    errorMessage: "L'email est requis et doit être valide.",
    validate: (value: string) => /\S+@\S+\.\S+/.test(value),
    isMandatory: true,
  },
  {
    name: 'phone',
    type: 'tel',
    placeholder: 'Téléphone',
    errorMessage: 'Ce champs est requis.',
    validate: (value: string) => value?.trim() !== '',
    isMandatory: true,
  },
]
