import React, { createContext, useContext, useEffect, useState } from 'react'
import { useContentfulSettings } from '~/hooks/use-contentful-settings'
import { useForm } from '~/hooks/use-form'
import { handleScore } from '~/utils/handleScore'
import { generateId, runTracking } from '~/utils/misc'
import {
  convertUserAnswersToQueryParams,
  convertUserDataFromQueryParamsToObject,
  type UserAnswers,
} from '~/utils/queryParameters'
import { PAGES_SLUGS, SETTINGS } from '~/utils/settings'
import { UIWithChildren } from '~/utils/types'

interface QuizContextType {
  questions?: Queries.QuestionDataFragment[]
  currentQuestionData?: Queries.QuestionDataFragment
  userSelectedAnswers: UserAnswers
  handleAnswerSelect: (questionIndex: number, answerIndex: number) => void
  hasQuizStarted: boolean
  setHasQuizStarted: (value: boolean) => void
  currentQuestion: number
  setCurrentQuestion: (value: number) => void
  isTransitioning: boolean
  setIsTransitioning: (value: boolean) => void
  handleQuizProgress: () => void
}

const QuizContext = createContext<QuizContextType | undefined>(undefined)

export const QuizProvider = ({ children }: UIWithChildren) => {
  const { profiles, questions } = useContentfulSettings()
  const [currentQuestion, setCurrentQuestion] = useState(0)
  const [hasQuizStarted, setHasQuizStarted] = useState(false)
  const [userSelectedAnswers, setuserSelectedAnswers] = useState<UserAnswers>(
    [],
  )
  const [isTransitioning, setIsTransitioning] = useState(false)
  const [shouldSubmit, setShouldSubmit] = useState(false)
  const [isLastQuestion, setIsLastQuestion] = useState(false)

  const { handleSubmit, setFormData, formData } = useForm()

  useEffect(() => {
    setIsLastQuestion(
      questions ? currentQuestion === questions.length - 1 : false,
    )
  }, [currentQuestion, questions])

  // const isLastQuestion = questions
  // ? currentQuestion === questions.length - 1
  // : null

  useEffect(() => {
    if (shouldSubmit) {
      handleSubmit({
        shouldValidateData: false,
        shouldRedirect: !!isLastQuestion,
      })
      setShouldSubmit(false)
    }
  }, [shouldSubmit, handleSubmit, formData])

  const handleAnswerSelect = (questionIndex: number, answerIndex: number) => {
    const updatedAnswers = userSelectedAnswers.map((answer) =>
      answer?.questionIndex === questionIndex
        ? { ...answer, userAnswerIndex: answerIndex }
        : answer,
    )

    setuserSelectedAnswers(
      updatedAnswers.some((answer) => answer?.questionIndex === questionIndex)
        ? updatedAnswers
        : [
            ...updatedAnswers,
            {
              questionIndex,
              userAnswerIndex: answerIndex,
            },
          ],
    )
  }

  const handleQuizProgress = () => {
    if (!hasQuizStarted) {
      runTracking('start')
      setHasQuizStarted(true)
      // save a localeid in the local storage
      localStorage.setItem('localId', generateId())
    } else {
      runTracking(`question_${currentQuestion + 1}`)

      const queryParameters =
        convertUserAnswersToQueryParams(userSelectedAnswers)
      const { user: global } = handleScore({
        questions,
        profiles,
        userAnswers: userSelectedAnswers,
      })
      const oldQueryparams = convertUserDataFromQueryParamsToObject()
      const cid = oldQueryparams?.cid

      if (isLastQuestion) {
        if (!!cid && !!SETTINGS.isEvaluationPageGenerated) {
          setFormData((prevState) => ({
            ...prevState,
            score: global.scoreByCorrectAnswer.toString(),
            scorepercentage: global.userProfilePercentageGtm.toString(),
            answers: convertUserAnswersToQueryParams(userSelectedAnswers),
            cid,
          }))
          setShouldSubmit(true)
        }
        if (!cid || (!!cid && !SETTINGS.isEvaluationPageGenerated)) {
          const newLocation = PAGES_SLUGS.results

          window.location.href = `/${newLocation}?${Object.values({
            queryParameters,
            scorePercent: `scorepercentage=${global.userProfilePercentageGtm}`,
            id: `localId=${generateId()}`,
            date: `date=${new Date().toISOString()}`,
          }).join('&')}`
        }
      } else {
        setFormData((prevState) => ({
          ...prevState,
          score: global.scoreByCorrectAnswer.toString(),
          scorepercentage: global.userProfilePercentageGtm.toString(),
          answers: convertUserAnswersToQueryParams(userSelectedAnswers),
          cid: !!cid ? cid : localStorage.getItem('localId') ?? '',
        }))
        setShouldSubmit(true)
        setIsTransitioning(true)
        setCurrentQuestion(
          (prevCurrentQuestion: number) => prevCurrentQuestion + 1,
        )
        setIsTransitioning(false)
      }
    }
  }

  const currentQuestionData = questions && questions[currentQuestion]

  return (
    <QuizContext.Provider
      value={{
        questions,
        hasQuizStarted,
        setHasQuizStarted,
        isTransitioning,
        setIsTransitioning,
        currentQuestion,
        setCurrentQuestion,
        currentQuestionData,
        userSelectedAnswers,
        handleAnswerSelect,
        handleQuizProgress,
      }}
    >
      {children}
    </QuizContext.Provider>
  )
}

export const useQuiz = (): QuizContextType => {
  const context = useContext(QuizContext)
  if (context === undefined) {
    throw new Error('useQuiz must be used within a QuizProvider')
  }
  return context
}
